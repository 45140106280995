import React from "react"
import Swiper from "react-id-swiper"
import Img from "gatsby-image"

import FeatherIcon from "feather-icons-react"

import iPadMockup from "../images/ipad_mockup.png"

const Slider = ({ title, images }) => {
  const swiperOptions = {
    speed: 1100,
    grabCursor: true,
    centeredSlides: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      bulletElement: "button",
      clickable: true,
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev button button--secondary px-0 w-12 flex-shrink-0 lg:absolute -ml-64 lg:ml-0 lg:-mt-12">
        <span className="sr-only"> vorheriges Bild anzeigen </span>
        <FeatherIcon icon="chevron-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next button button button--secondary px-0 w-12 flex-shrink-0 -mr-72 -mb-12 mt-12 lg:mt-0 lg:mb-0 lg:mr-0 lg:-mt-24">
        <span className="sr-only"> nächstes Bild anzeigen </span>
        <FeatherIcon icon="chevron-right" />
      </button>
    ),
  }

  return (
    <section className="content bg-gradient-indigo-dark-purple overflow-hidden flex flex-col items-center overflow-hidden">
      <h2 className="text-white text-center">
        {title}
      </h2>
      <img
        src={iPadMockup}
        className="max-w-4xl w-full absolute z-10 mt-16 pointer-events-none select-none hidden lg:block"
      />
      <div className="container flex justify-center items-center relative z-0">
        <Swiper {...swiperOptions}>
          {images.map((image, i) => (
            <div
              className="flex flex-col mt-4 lg:mt-10 items-center flex-shrink-0 max-w-full h-full relative"
              key={i}
            >
              <Img
                fluid={image.fluid}
                style={{ maxWidth: "40.5rem", width: "100%" }}
                alt={image.description}
              />
              <p className="text-center text-white mt-8 lg:mt-16">
                {image.description}
              </p>
            </div>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default Slider
