import React from "react"

const USP = ({ isSmall = false, icon, description }) => {
  if (isSmall) {
    return (
      <div className="my-3 px-10 w-5/6 sm:w-1/2  md:my-6 md:px-12 md:w-2/4 lg:w-1/4 xl:w-1/4 flex flex-col items-center">
        <div className="w-32 h-32 md:w-36 md:h-36 bg-gradient-indigo-dark-purple rounded-full mb-6 flex items-center justify-center">
          <img
            className="w-16 h-16 md:w-20 md:h-20"
            src={icon.url}
            alt={description}
          />
        </div>
        <p className="text-center mb-10 lg:mb-0">
          {description}
        </p>
      </div>
    )
  } else {
    return (
      <div className="my-3 w-2/4 md:my-6 md:px-6 md:w-2/4 lg:w-1/3 xl:w-1/3 flex flex-col items-center">
        <div className="w-32 h-32 md:w-40 md:h-40 lg:w-44 lg:h-44 bg-gradient-indigo-dark-purple rounded-full mb-6 flex justify-center items-center">
          <img
            className="w-16 h-16 md:w-24 md:h-24"
            src={icon.url}
            alt={description}
          />
        </div>
        <p className="text-center mb-10 lg:mb-0"> {description}</p>
      </div>
    )
  }
}
export default USP
