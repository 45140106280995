import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import Intro from "../components/intro"
import Cta from "../components/cta"
import Slider from "../components/slider"
import USP from "../components/usp"

const IndexPage = () => {
  const home = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        subheadline
        subline {
          subline
        }
        introImage {
          fluid(maxWidth: 900, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        startImage {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        startText {
          startText
        }
        startTitle
        usps {
          icon {
            file {
              url
            }
          }
          description {
            description
          }
        }
        sliderTitle
        sliderImages {
          description
          fluid(maxWidth: 600, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        useCases {
          icon {
            file {
              url
            }
          }
          description {
            description
          }
        }
        socialImage {
          fixed(width: 2000) {
            src
          }
        }
      }
    }
  `)

  const homeData = home.contentfulHomepage

  const introData = {
    subheadline: homeData.subheadline,
    subline: homeData.subline.subline,
    image: homeData.introImage,
  }

  const sliderData = {
    title: homeData.sliderTitle,
    images: homeData.sliderImages,
  }

  return (
    <Layout>
      <Header />
      <SEO
        image={`https:${homeData.socialImage.fixed.src}`}
        title={`GUIDO – ${introData.subheadline}`}
        description={homeData.startText.startText}
      />
      <Intro {...introData} />
      <section className="container m-auto" id="was-ist-guido">
        <div className="content flex flex-col justify-between md:flex-row md:items-center">
          <div className="mr-10">
            <h2 className="text-dark-blue">{homeData.startTitle}</h2>
            <p>{homeData.startText.startText}</p>
          </div>
          <Img
            fluid={homeData.startImage.fluid}
            className="w-full md:w-2/3 self-center lg:w-2/3"
          />
        </div>
      </section>
      <section className="w-full" id="was-kann-guido">
        <div className="container content flex items-center flex-col">
          <h2 className="text-dark-blue text-center">Was kann Guido?</h2>
          <div className="container flex flex-wrap flex-col md:flex-row justify-between items-center md:items-start">
            {homeData.usps.map((usp, i) => (
              <USP
                icon={usp.icon.file}
                description={usp.description.description}
                key={i}
              />
            ))}
          </div>
        </div>
      </section>
      <Slider {...sliderData} />
      <section className="w-full">
        <div className="container content flex justify-center items-center flex-col">
          <h2 className="text-dark-blue text-center">
            Wieso brauche ich Guido?
          </h2>
          <div className="container flex flex-wrap flex-col md:flex-row justify-center items-center md:items-start">
            {homeData.useCases.map((useCase, i) => (
              <USP
                icon={useCase.icon.file}
                description={useCase.description.description}
                key={i}
                isSmall
              />
            ))}
          </div>
        </div>
      </section>
      <Cta />
    </Layout>
  )
}

export default IndexPage
