import React from "react"
import FeatherIcon from "feather-icons-react"

const Cta = () => (
  <section
    className="w-full bg-gradient-indigo-dark-purple"
    id="wie-bekomme-ich-guido"
  >
    <div className="container content flex justify-center flex-col items-center">
      <h2 className="text-white text-center">Wie bekomme ich Guido?</h2>
      <p className="text-white text-center mb-8">
        Melden Sie sich einfach ganz unverbindlich bei uns. Stefan Clasen freut
        sich auf Ihre Kontaktaufnahme unter:{" "}
        <a href="mailto:stefan.clasen@voll.digital">
          stefan.clasen@voll.digital
        </a>
      </p>
      <a
        href="mailto:stefan.clasen@voll.digital"
        className="button button--primary"
      >
        <FeatherIcon icon="mail" className="mr-2 w-5 h-5" />
        Unverbindlich anfragen
      </a>
    </div>
  </section>
)

export default Cta
