import React, { useState, useEffect } from "react"
import { Link, animateScroll as scroll } from "react-scroll"
import anime from "animejs"

import GuidoLogo from "./guido-logo"
import FeatherIcon from "feather-icons-react"

const Header = () => {
  const [isMobileNavToggled, setMobileNavToggledState] = useState(false)
  const [isHeaderAboveContent, toggleHeaderState] = useState(false)

  useEffect(() => {
    const bodyClassList = document.querySelector("body").classList

    isMobileNavToggled
      ? bodyClassList.add("overflow-hidden", "lg:overflow-auto")
      : bodyClassList.remove("overflow-hidden", "lg:overflow-auto")
  })

  // only run once on mount
  useEffect(() => {
    // add event listener to toggle header state
    const pageTitleOffsetTop = document
      .getElementById("page-title")
      .getBoundingClientRect().top

    window.addEventListener("scroll", () => {
      toggleHeaderState(window.pageYOffset > pageTitleOffsetTop - 100)
    })

    // animate nav links
    anime({
      targets: ".nav__animate",
      opacity: [0, 1],
      translateY: ["1rem", "0"],
      delay: anime.stagger(50),
      duration: 1000,
      easing: "easeOutQuint",
    })
  }, [])

  const navItems = [
    { name: "Was ist Guido?", slug: "was-ist-guido" },
    { name: "Was kann Guido?", slug: "was-kann-guido" },
    { name: "Wie bekomme ich Guido?", slug: "wie-bekomme-ich-guido" },
  ]

  return (
    <header
      className={`w-full lg:h-20 flex items-start fixed z-50 transition-all duration-500 ease-in-out overflow-hidden ${
        isMobileNavToggled ? "h-screen bg-indigo lg:bg-transparent" : "h-20"
      } ${isHeaderAboveContent ? "bg-indigo" : ""}`}
    >
      <nav className="container flex items-center justify-between mx-auto pt-4">
        <Link
          to="/"
          smooth={true}
          duration={1000}
          className="nav__animate cursor-pointer p-px inline-block opacity-0 cursor-pointer focus:outline-none rounded focus:shadow-outline"
          onClick={scroll.scrollToTop}
          title="Nach oben scrollen"
        >
          <GuidoLogo className="h-8 w-auto" />
        </Link>
        <button
          className="w-12 h-12 text-white lg:hidden cursor-pointer flex justify-center relative z-10 focus:outline-none"
          onClick={() => setMobileNavToggledState(!isMobileNavToggled)}
        >
          {isMobileNavToggled ? (
            <>
              <span className="sr-only">Menü schließen</span>
              <FeatherIcon icon="x" />
            </>
          ) : (
            <>
              <span className="sr-only">Menü öffnen</span>
              <FeatherIcon icon="menu" />
            </>
          )}
        </button>
        <ul
          className={`px-6 pt-64 mt-4 lg:mt-0 flex flex-col lg:flex-row absolute lg:static z-0 left-0 w-full lg:pt-0 lg:inline-flex lg:w-auto transition-opacity ease-in-out duration-500 ${
            isMobileNavToggled ? "opacity-100" : "opacity-0 lg:opacity-100 pointer-events-none lg:pointer-events-auto"
          }`}
        >
          {navItems.map((navItem, i) => (
            <li
              className="nav__animate mb-4 lg:mb-0 lg:ml-10 text-white text-base uppercase hover:text-cyan cursor-pointer"
              key={i}
            >
              <Link
                to={navItem.slug}
                smooth={true}
                duration={1000}
                activeClass="underline text-cyan"
                className="transition-colors h-12 w-full lg:w-auto inline-flex items-center duration-200 no-underline focus:outline-none focus:underline"
                title={`Zum Abschnitt "${navItem.name}" scrollen`}
                offset={-80}
                onClick={() => setMobileNavToggledState(false)}
              >
                {navItem.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}

export default Header
