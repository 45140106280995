import React, { useEffect } from "react"
import anime from "animejs"

import GuidoLogo from "./guido-logo"
import Img from "gatsby-image"

const Intro = ({ subheadline, subline, image }) => {
  useEffect(() => {
    anime({
      targets: ".animate",
      opacity: [0, 1],
      translateY: ["1.5rem", "0"],
      delay: anime.stagger(175),
      duration: 900,
      easing: "easeOutQuart",
    })
  }, [])

  return (
    <section className="intro pt-32 w-full bg-gradient-indigo-dark-purple flex justify-center items-center text-white py-8">
      <div className="container relative">
        <div className="z-10 relative md:-mt-24 lg:mt-0">
          <h1 className="sr-only" id="page-title">
            Guido
          </h1>
          <GuidoLogo className="mb-4 w-40 lg:w-56 animate opacity-0" />
          <h3 className="text-xl animate">{subheadline}</h3>
        </div>
        <div className="w-full mt-20 md:mt-0 md:absolute md:bottom-0 md:top-0 md:right-0 md:w-2/3 md:z-0 md:flex md:items-center xl:-mr-6 2xl:-mr-24 md:-mb-24 lg:mb-0 animate">
          <Img fluid={image.fluid} className="sm:w-3/4 md:w-full" />
        </div>
        <div className="z-10 relative md:w-1/3 lg:2/5">
          <p className="mt-12 pt-2 lg:text-lg animate">{subline}</p>
          <span className="inline-block uppercase text-cyan mt-4 md:mt-40 animate">
            schnell | verbindlich | aktuell
          </span>
        </div>
      </div>
    </section>
  )
}

export default Intro
